import axios from 'axios'
import store from '@/store'
import router from '@/router/lk-router'

const baseURL = `${window.location.origin}/backend-cryptoenter`

const axiosInstance = axios.create({
  baseURL
})

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.status !== 200) return Promise.reject(response?.data?.message)

    return response.data.payload
  }, async function (error) {
    if (error?.response?.status === 401) {
      await store.dispatch('signOut')
      if (window.location.pathname !== '/lk/login') router.push('/lk/login')
    }
    return Promise.reject(error)
})

export default axiosInstance
