import Store from '@/store'

export const deepCopy = (obj = []) => JSON.parse(JSON.stringify(obj))

export const parseUserWallets = walletsList => {
  return walletsList.reduce((acc, walletItem) => {
    // ОГРАНИЧЕНИЕ! на данный момент (13.07.2023) только у
    // кошелька MAIN ext_type === null
    const walletType = walletItem.ext_type ?? walletItem.wallet.type
    if (acc[walletType]) acc[walletType].push(walletItem)
    else acc[walletType] = [walletItem]
    return acc
  }, {})
}

export const getQueryObjectFromString = str => {
  const query = {}
  const queryString = str.split('?').pop()
  const queryParamsStrings = queryString?.split('&') || []
  queryParamsStrings.forEach(str => {
    const [key, val] = str.split('=')
    query[key] = val
  })
  return query
}

export const getOnlyDigitsFromLocale = stringVal => {
  if (!stringVal) return ''
  return (stringVal.match(/\d+/g) || []).join('')
}

export const stringValueIsFiat = str => Store.getters.fiatCurrencies.includes(str)

export const stringPairIncludesFiat = pairString => {
  if (!pairString || typeof pairString !== 'string') return false
  const currencies = pairString.split('/')
  return currencies.some(curr => Store.getters.fiatCurrencies.includes(curr))
}

export const getDeepSortedPropsObj = (obj) => {
  const isArray = Array.isArray(obj)
  const sortedData = isArray ? [] : {}
  if (isArray) {
    obj.forEach(elem => {
      const isObj = typeof elem === 'object' && !Array.isArray(elem)
      sortedData.push(!isObj ? elem : getDeepSortedPropsObj(elem))
    })
  } else {
    Object.keys(obj).sort((a, b) => a.localeCompare(b)).forEach(prop => {
      const isObj = typeof obj[prop] === 'object' && !Array.isArray(obj[prop])
      if (typeof obj[prop] !== 'boolean' && !obj[prop]) return
      sortedData[prop] = !isObj ? obj[prop] : getDeepSortedPropsObj(obj[prop])
    })
  }
  return sortedData
}

export const isFirstWordEarlier = (word1, word2) => {
    return word1.localeCompare(word2) < 0;
}

export const bytesToMegabytes = (bytes) => {
    if (bytes < 0) {
        throw new Error("Количество байт не может быть отрицательным");
    }

    const megabytes = bytes / (1024 * 1024);
    return Math.ceil(megabytes * 100) / 100; // Округление в большую сторону до двух знаков после запятой
}

export const downloadFile = (href, name) => {
  const link = document.createElement('a')
  link.href = href
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getFileName = contentDisposition => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDisposition)
  const filename = matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown'
  return decodeURIComponent(filename.replace(/=\?UTF-8\?Q\?/g, '').replace(/\?=/g, '').replace(/=/g, '%'))
}
